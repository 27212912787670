import { isConferencePromo, isSessionDetail, traverseAndModify } from '../../../lib'

const isSessionObject = (obj) => {
  return (obj.contentId && isConferencePromo(obj)) || (obj.id && isSessionDetail(obj))
}

/**
 * Extracts session IDs from the provided data and maps them to their paths.
 *
 * @param {Object} data - The data object to traverse and extract session IDs from.
 * @returns {Object} An object where the keys are session IDs and the values are their corresponding paths.
 */
const getSessionIds = (data) => {
  const ids = {}

  traverseAndModify(data, isSessionObject, (obj, path) => {
    ids[obj.id || obj.contentId] = path
  })
  return ids
}

export default getSessionIds
