import { useEffect, useState } from 'react'
import { useUser } from '@fs/zion-user'
import getSessionIds from './helpers'
import { getHistoryEntries } from '../../api'
import useStableObject from '../useStableObject'
import { deepCopy } from '../../lib'
import { getChunkedPlaylistsByItems } from '../../lib/helpers/playlistsHelpers'

const injectItem = ({ data, path, item, key }) => {
  if (!item) {
    return
  }

  let current = data
  for (let i = 0; i < path.length - 1; i++) {
    current = current[path[i]]
  }
  current[path[path.length - 1]][key] = item
}

const inject = async (data) => {
  const idMap = getSessionIds(data)
  const ids = Object.keys(idMap)
  const requests = [getChunkedPlaylistsByItems, getHistoryEntries]
  const responses = await Promise.all(requests.map((req) => req(ids)))

  // for each id, inject the data
  const mutableData = deepCopy(data)
  Object.entries(idMap).forEach(([id, path]) => {
    // handle playlists
    const playlistItem = responses[0][id]?.results
    injectItem({ data: mutableData, path, item: playlistItem, key: 'playlists' })

    // handle watch history
    const whatHistoryItem = responses[1].find((entry) => entry.sessionId === id)
    injectItem({ data: mutableData, path, item: whatHistoryItem, key: 'watchHistory' })
  })

  return mutableData
}

/**
 * This is used to get data from a service and inject it into the detail data so that we can batch all the ids at the top and save on network requests. The data that is injected is then used in components down th chain to display applicable information.
 *
 * @param {Object} params.data - The initial data to be processed.
 * @param {boolean} params.loading - The loading state.
 * @returns {Object} return.data - The processed or initial data.
 * @returns {boolean} return.loading - The processing or loading state.
 */
const useDetailInjection = ({ data, loading }) => {
  const [processing, setProcessing] = useState(true)
  const [state, setState] = useState(data)
  const { signedIn } = useUser()

  const updateState = async (d) => {
    setState(await inject(d))
    setProcessing(false)
  }

  const stableData = useStableObject(data)
  useEffect(() => {
    let current = true
    if (!loading && signedIn && stableData) {
      current && updateState(stableData)
    } else {
      setProcessing(false)
    }
    return () => {
      current = false
    }
  }, [loading, signedIn, stableData])

  return { data: state || data, loading: loading || processing }
}

export default useDetailInjection
